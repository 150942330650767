<template>
    <div class="content">
        <dv-scroll-ranking-board :config="config" style="width:400px;height:300px" />
    </div>
</template>
<script>
import request from '@/request/http.js'
export default {
    data() {
        return {
            config: {
                data: [
                ], digitalFlopStyle: {
                    fontSize: 18,
                    fontWeight: 'bold'
                }
            }
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            await request('get', '/customers/clues/getAllTopArea/3185')
                .then((response) => {
                    response.data.data[0].forEach(res => {
                        this.config.data.push({
                            name: res.name,
                            value: res.count
                        })
                    });
                    this.config = { ...this.config };//必不可少，不可省略
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
</script>
<style lang="scss" scoped>
.content{
    width: 100%;
    padding: 5%;
}
</style>