<template>
        <div class="title">
                <div class="content">
                        <p>{{ title }}</p>
                        <dv-digital-flop animationCurve="easeOutCubic" animationFrame="1000" :config="config"
                                style="width:180px;height:60px;" />
                </div>
        </div>
</template>
<script>
import request from '@/request/http.js'
export default {
        data() {
                return {
                        config: {
                                number: [0],
                                style: {
                                        fontSize: 35,
                                        fontWeight: 'bold',
                                        fill: 'rgb(255, 255, 255)'
                                }
                        }
                }
        },
        props: {
                title: {
                        type: String,
                        required: true,
                },
        },
        mounted() {
                setInterval(() => {
                        this.power();
                }, 5000);
        },
        methods: {
                async power() {
                        var url = "/customers/getTotal/3185";
                        var name = "";
                        if(this.title == "设备总数"){
                                await request('get', "/customers/devices/getTotalAndName/3185")
                                .then((response) => {
                                        this.config.number[0] = response.data.data[0].dataValue;
                                        this.config = { ...this.config };//必不可少，不可省略
                                })
                                .catch((error) => {
                                        console.error(error);
                                }); 
                                return;
                        }
                        switch (this.title) {
                                case '已分配数':
                                        name = "getDistributed";
                                        url = "/customers/clues/getTotal/3185";
                                        break;
                                case '未分配数':
                                        name = "getUndistributed";
                                        url = "/customers/clues/getTotal/3185";
                                        break;
                                case '当月新增':
                                        name = "getMonthAdd";
                                        url = "/customers/clues/getTotal/3185";
                                        break;
                                case '15天未跟进数':
                                        name = "getDay15UnFollow";
                                        url = "/customers/clues/getTotal/3185";
                                        break;
                                case '客户总数':
                                        name = "total";
                                        url = "/customers/getTotal/3185";
                                        break;
                                case '线索总数':
                                        name = "getTotal";
                                        url = "/customers/clues/getTotal/3185";
                                        break;
                                case '设备总数':
                                        name = "dataName";
                                        url = "/customers/devices/getTotalAndName/3185";
                                        break;
                                case '微信好友总数':
                                        name = "friendsTotal";
                                        url = "/customers/wechats/getTotal/3185";
                                        break;
                                case '当月进粉':
                                        name = "monthAdd";
                                        url = "/customers/getTotal/3185";
                                        break;
                                case '当月电话客户':
                                        name = "phoneAddTotal";
                                        url = "/customers/getTotal/3185";
                                        break;
                                case '线索转客户':
                                        name = "clueTransTotal";
                                        url = "/customers/getTotal/3185";
                                        break;
                                case '15天未跟进':
                                        name = "day15Unfollow";
                                        url = "/customers/getTotal/3185";
                                        break;

                        }
                        await request('get', url)
                                .then((response) => {
                                        this.config.number[0] = response.data.data.find(item => item.dataName === name).dataValue;
                                        this.config = { ...this.config };//必不可少，不可省略
                                })
                                .catch((error) => {
                                        console.error(error);
                                });
                }
        }
}
</script>
<style lang="scss" scoped>
.title {
        width: 40%;
        display: flex;
        flex-wrap: nowrap;
        margin: 0 4%;

        .content {
                flex: 1;
                margin: 10px;
                background-image: url("../../assets/img/ksh40.png");
                background-size: 100% 100%;
                background-position: center center;

                p {
                        font-size: 18px;
                        font-weight: 600;
                        color: #ffffff;
                        padding: 20px 0 0 20px;
                }
        }
}
</style>