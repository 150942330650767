import axios from 'axios'; // 引入axios

// 环境的切换
if (process.env.NODE_ENV == 'dev') {
    axios.defaults.baseURL = 'http://localhost:7013';
}
else if (process.env.NODE_ENV == 'test') {
    axios.defaults.baseURL = 'https://www.ceshi.com';
}
else if (process.env.NODE_ENV == 'prd') {
    axios.defaults.baseURL = 'https://www.production.com';
}

const instance = axios.create({
    baseURL: '/scrm',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});
var company_id = window.location.href.split("=")[1];
company_id = company_id ? company_id : 0;
export default function request(method, url, data = null) {
    url = url.replace(3185, company_id);
    method = method.toLowerCase();
    if (method === 'get') {
        return instance.get(url, {
            params: data,
        });
    } else if (method === 'post') {
        return instance.post(url, data);
    } else if (method === 'put') {
        return instance.put(url, data);
    } else if (method === 'delete') {
        return instance.delete(url, {
            params: data,
        });
    } else {
        console.error(`未知的 method 类型：${method}`);
        return false;
    }
}