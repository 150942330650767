//1. 导入 Vue 和 VueRouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'

//引入路由组件
import Index from '../views/Index.vue'
import IndexOrder from '../views/IndexOrder.vue'
//2. 调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(VueRouter)

//3. 创建路由的实例对象
//创建router实例对象，去管理一组一组的路由规则
const router = new VueRouter({
    routes: [
        {
            path: '/scrm',
            component: Index,
        },
        {
            path: '/order',
            component: IndexOrder,
        },
    ],
})

//暴露router
export default router