<template>
    <div class="box">
        <div id="lineCharts" :style="{ width: '1200px', height: '400px', left: '-90px' }"></div>
    </div>
</template>
   
<script>
import request from '@/api/erpHttp.js'
import echarts from "echarts"
export default {

    data() {
        return {
            fontColor: '#30eee9',
            date: [],
            count: [],
            sum_total: [],
            max:100
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var that = this
            await request('get', '/orders/monthDealCurve/3185')
                .then((response) => {
                    response.data.data[0].forEach(res => {
                            that.date.push(res.days),
                            that.count.push(res.count),
                            that.sum_total.push(res.sum_total / 10000)
                    }
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
    mounted() {
        // 基于准备好的dom，初始化echarts实例

        var myChart = this.$echarts.init(document.getElementById('lineCharts'))

        var that = this;
        // 指定图表的配置项和数据

        var option = {
            title: {
                text: this.title,
                top: '5%',
                left: 'center',
                textStyle: {
                    color: '#FFF',
                    align: 'center',
                }
            },
            grid: {
                left: '9%',
                right: '10%',
                top: '20%',
                bottom: '15%',
                containLabel: true
            },
            tooltip: {
                show: true,
                trigger: 'item',
                position: 'top',
                formatter: function (params) {
                    return params.name + '<br/>' + params.seriesName + ' : ' + params.value;
                }
            },
            legend: {
                show: true,
                x: 'center',
                top: '12%',
                y: '35',
                icon: 'stack',
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                    color: '#1bb4f6'
                },
                data: ['单量', '金额（万元）']
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                axisLabel: {
                    color: this.fontColor
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: '#397cbc'
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#195384'
                    }
                },
                data: this.date
            }],
            yAxis: [{
                type: 'value',
                name: '',
                min: 0,
                max: this.max,
                axisLabel: {
                    formatter: '{value}',
                    textStyle: {
                        color: '#2ad1d2'
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: '#27b4c2'
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#11366e'
                    }
                }
            }
            ],
            series: [{
                name: '单量',
                type: 'line',
                //stack: '总量',
                symbol: 'circle',
                symbolSize: 8,
                itemStyle: {
                    normal: {
                        color: '#0092f6',
                        lineStyle: {
                            color: "#0092f6",
                            width: 1
                        },
                        label: { show: true },
                        areaStyle: {
                            //color: '#94C9EC'
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: 'rgba(7,44,90,0.3)'
                            }, {
                                offset: 1,
                                color: 'rgba(0,146,246,0.9)'
                            }]),
                        }
                    }
                },
                markPoint: {
                    itemStyle: {
                        normal: {
                            color: 'red'
                        }
                    }
                },
                data: that.count
            },
            {
                name: '金额（万元）',
                type: 'line',
                //stack: '总量',
                symbol: 'circle',
                symbolSize: 8,

                itemStyle: {
                    normal: {
                        color: '#00d4c7',
                        lineStyle: {
                            color: "#00d4c7",
                            width: 1
                        },
                        label: {
                            show: true,
                            formatter: function (params) {
                                return Math.floor(params.value); // 返回值向下取整
                            }},
                            areaStyle: {
                                //color: '#94C9EC'
                                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                    offset: 0,
                                    color: 'rgba(7,44,90,0.3)'
                                }, {
                                    offset: 1,
                                    color: 'rgba(0,212,199,0.9)'
                                }]),
                            }
                        }
                    },
                    data: that.sum_total
                }
            ]

        }

        // 使用刚指定的配置项和数据显示图表。
        setTimeout(() => {
            myChart.setOption(option)
        }, 2000);

    }

}
</script>
<style scoped>
.box {
    margin-top: 50px;
}
</style>