<template>
    <dv-full-screen-container>
        <div class="container">
            <Header title="客户"></Header>
            <div class="content">
                <div class="left_container">
                    <div class="cell">
                        <Border title="线索总览">
                            <div class="cell_content">
                                <DigitalFlopScrm title="已分配数" />
                                <DigitalFlopScrm title="未分配数" />
                                <DigitalFlopScrm title="当月新增" />
                                <DigitalFlopScrm title="15天未跟进数" />
                            </div>
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="线索渠道分布">
                            <Ring title="线索" />
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="线索区域分布">
                            <RankingBoard />
                        </Border>
                    </div>
                </div>

                <div class="center_container">
                    <div class="cell_top">
                        <Border title="系统总览">
                            <div class="cell_content">
                                <DigitalFlop title="客户总数" />
                                <DigitalFlop title="线索总数" />
                                <DigitalFlop title="设备总数" />
                                <DigitalFlop title="微信好友总数" />
                            </div>
                        </Border>
                    </div>
                    <div class="cell_bottom">
                        <div class="cell">
                            <ColumnCharts />
                        </div>
                        <div class="cell">
                            <LineCharts title="当月电话"/>
                        </div>
                    </div>

                </div>
                <div class="right_container">
                    <div class="cell">
                        <Border title="客户总览">
                            <div class="cell_content">
                                <DigitalFlopScrm title="当月进粉" />
                                <DigitalFlopScrm title="当月电话客户" />
                                <DigitalFlopScrm title="线索转客户" />
                                <DigitalFlopScrm title="15天未跟进" />
                            </div>
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="客户渠道分布">
                            <Ring title="客户" />
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="客户区域分布">
                            <ScrollBoard />
                        </Border>
                    </div>
                </div>
            </div>
        </div>
    </dv-full-screen-container>
</template>
<script>
import Ring from './index/Ring.vue';
import Header from './index/Header.vue';
import DigitalFlop from './index/DigitalFlop.vue';
import DigitalFlopScrm from './index/DigitalFlopScrm.vue';
import Border from './index/Border.vue';
import RankingBoard from './index/RankingBoard.vue';
import ScrollBoard from './index/ScrollBoard.vue';
import LineCharts from './index/LineCharts.vue';
import ColumnCharts from './index/ColumnCharts.vue';
export default {
    data() {
        return {
            company_id: 0
        }
    },
    components: {
        Ring, Header, DigitalFlop, Border, RankingBoard, ScrollBoard, LineCharts, ColumnCharts,DigitalFlopScrm
    },
    mounted() {
        var company_id = window.location.href.split("=")[1];
        company_id = company_id ? company_id : 10;
        this.company_id = company_id;
    }

}
</script>
<style lang="scss" scoped>
.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("@/assets/img/pageBg.png");
    background-size: cover;
    background-position: center center;

    .content {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        justify-content: space-between;

        .left_container,
        .right_container {
            display: flex;
            flex-direction: column;
            width: 23%;
            height: 100%;

            .cell {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .cell_content {
                    padding-right: 35px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }

        .center_container {
            display: flex;
            flex-direction: column;
            width: 53%;
            height: 100%;
            justify-content: space-between;

            .cell_top {
                height: 180px;
                width: 100%;
                display: flex;
                color: aliceblue;
                flex-direction: row;
                flex: 1;

                .cell_content {
                    width: 70%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    flex-wrap: no-wrap;
                    padding: 0 150px;
                }
            }

            .cell_bottom {
                height: calc(100% - 180px); // 减去cell_top的高度
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .cell {
                    flex: 1;
                    height: 45%;
                    width: 100%;
                }
            }
        }
    }
}
</style>