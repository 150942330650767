<template>
  <div class="box">
    <div id="columnCharts" :style="{ width: '1000px', height: '350px' }"></div>
  </div>
</template>
   
<script>
import request from '@/request/http.js'
import echarts from "echarts"
export default {
  data() {
    return {
      name: [],
      countAll: [],
      countDeal: []
    }
  },

  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await request('get', '/customers/getAllChannelAndTypeA/3185')
        .then((response) => {
          response.data.data[0].forEach(res => {
            this.name.push(res.name),
              this.countAll.push(res.countAll),
              this.countDeal.push(res.countDeal)
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },
  mounted() {
    // 基于准备好的dom，初始化echarts实例

    var myChart = this.$echarts.init(document.getElementById('columnCharts'))
    var that = this;
    // 指定图表的配置项和数据

    var option = {
      title: {
        text: '客户渠道',
        x: 'center',
        textStyle: {
          color: '#fff'
        }
      },
      tooltip: { //提示框组件
        trigger: 'axis',
        formatter: '{b}<br />{a0}: {c0}<br />{a1}: {c1}',
        axisPointer: {
          type: 'shadow',
          label: {
            backgroundColor: '#6a7985'
          }
        },
        textStyle: {
          color: '#fff',
          fontStyle: 'normal',
          fontFamily: '微软雅黑',
          fontSize: 12,
        }
      },
      grid: {
        left: '1%',
        right: '4%',
        bottom: '6%',
        top: 30,
        padding: '0 0 10 0',
        containLabel: true,
      },
      legend: { //图例组件，颜色和名字
        right: 10,
        top: 0,
        itemGap: 16,
        itemWidth: 18,
        itemHeight: 10,
        data: [{
          name: '总数',
          //icon:'image://../wwwroot/js/url2.png', //路径
        },
        {
          name: 'A类',
        }
        ],
        textStyle: {
          color: '#a8aab0',
          fontStyle: 'normal',
          fontFamily: '微软雅黑',
          fontSize: 12,
        }
      },
      xAxis: [{
        type: 'category',
        boundaryGap: true, //坐标轴两边留白
        data: that.name,
        axisLabel: { //坐标轴刻度标签的相关设置。
          interval: 0, //设置为 1，表示『隔一个标签显示一个标签』
          margin: 15,
          textStyle: {
            color: '#078ceb',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 12,
          }
        },
        axisTick: { //坐标轴刻度相关设置。
          show: false,
        },
        axisLine: { //坐标轴轴线相关设置
          lineStyle: {
            color: '#fff',
            opacity: 0.2
          }
        },
        splitLine: { //坐标轴在 grid 区域中的分隔线。
          show: false,
        }
      }],
      yAxis: [{
        type: 'value',
        splitNumber: 5,
        axisLabel: {
          textStyle: {
            color: '#a8aab0',
            fontStyle: 'normal',
            fontFamily: '微软雅黑',
            fontSize: 12,
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#fff'],
            opacity: 0.06
          }
        }

      }],
      series: [{
        name: '总数',
        type: 'bar',
        data: that.countAll,
        barWidth: 10,
        barGap: 0, //柱间距离
        label: { //图形上的文本标签
          normal: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#a8aab0',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            },
          },
        },
        itemStyle: { //图形样式
          normal: {
            barBorderRadius: [5, 5, 0, 0],
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 1,
              color: 'rgba(127, 128, 225, 0.7)'
            }, {
              offset: 0.9,
              color: 'rgba(72, 73, 181, 0.7)'
            }, {
              offset: 0.31,
              color: 'rgba(0, 208, 208, 0.7)'
            }, {
              offset: 0.15,
              color: 'rgba(0, 208, 208, 0.7)'
            }, {
              offset: 0,
              color: 'rgba(104, 253, 255, 0.7)'
            }], false),
          },
        },
      },
      {
        name: 'A类',
        type: 'bar',
        data: that.countDeal,
        barWidth: 10,
        barGap: 0.2, //柱间距离
        label: { //图形上的文本标签
          normal: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#a8aab0',
              fontStyle: 'normal',
              fontFamily: '微软雅黑',
              fontSize: 12,
            },
          },
        },
        itemStyle: { //图形样式
          normal: {
            barBorderRadius: [5, 5, 0, 0],
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 1,
              color: 'rgba(127, 128, 225, 0.7)'
            }, {
              offset: 0.9,
              color: 'rgba(72, 73, 181, 0.7)'
            }, {
              offset: 0.25,
              color: 'rgba(226, 99, 74, 0.7)'
            }, {
              offset: 0,
              color: 'rgba(253, 200, 106, 0.7)'
            }], false),
          },
        },
      }
      ]

    }


    // 使用刚指定的配置项和数据显示图表。
    setTimeout(() => {
      myChart.setOption(option)
    }, 2000);

  }

}
</script>
<style scoped>
.box {
  margin-top: 50px;
}
</style>