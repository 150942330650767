<template>
    <div class="box">
        <dv-active-ring-chart :config="config" style="width:300px;height:300px;" />
        <div class="title">
            <p v-for="(item, index) in config.data" :key="index" :style="{color:config.color[index]}">{{ item.name }}</p>
        </div>
    </div>
</template>
<script>
import request from '@/api/erpHttp.js'
export default {
    data() {
        return {
            config: {
                data: [],
                digitalFlopStyle: {
                    fontSize: 18,
                    fontWeight: 'bold'
                },
                color: ["#FF5D1D", "#FFA91F", "#FFF803", "#9DF90D", "#11EA00", "#00FFF8", "#038CEA", "#2948FF", "#B283FC", "#A000EA", "#F746EA", "#AF1E59"],
                showOriginValue: true
            }
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            await request('get', "/orders/monthOrderChannel/3185")
                .then((response) => {
                    response.data.data[0].forEach(res => {
                        this.config.data.push({
                            name: res.name,
                            value: res.count
                        })
                    });
                    this.config = { ...this.config };//必不可少，不可省略
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dv-active-ring-chart {
        width: 70%;
        font-size: 16px;
    }

    .title {
        height: 100%;
        padding-top: 3%;
        width: 35%;
        font-size: 15px;
        font-weight: 600;
        color: white;
    }
}
</style>