<template>
    <div class="d-flex jc-center title_wrap">
        <dv-border-box-1 style="left: -4px;">
            <div class="title_container">
                <div class="company_name">{{ companyName }}</div>
                <!-- <div class="subtitle">{{subtitle}}</div> -->
            </div>
        </dv-border-box-1>
        <div class="zuojuxing"></div>
        <div class="youjuxing"></div>
        <div class="guang"></div>
        <div class="d-flex jc-center">
            <div class="title">
                <span class="title-text">智神系统{{title}}数据驾驶舱</span>
            </div>
        </div>
        <div class="timers">
            <dv-border-box-1>
                <div class="title_container">
                    <p>{{ currentTime }}</p>
                </div>
            </dv-border-box-1>
        </div>
    </div>
</template>
<script>
import request from '@/request/http.js'
export default {
    data() {
        return {
            currentTime: '', // 当前时间变量
            companyName: '靠自己酒业',
            subtitle: '--这里是公司的SLOGAN'
        }
    },
    props:{
        title: {
                        type: String,
                        required: true,
                },
    },
    mounted() {
        this.startTimer(); // 组件加载后开始计时
        this.getCompanyName();
    },
    methods: {
        startTimer() {
            setInterval(() => {
                const date = new Date(); // 获取当前日期对象
                let yy = date.getFullYear();
                let mm = date.getMonth() + 1;
                let dd = date.getDate();
                let hours = date.getHours().toString().padStart(2, '0'); // 小时（两位数）
                let minutes = date.getMinutes().toString().padStart(2, '0'); // 分钟（两位数）
                let seconds = date.getSeconds().toString().padStart(2, '0'); // 秒钟（两位数）

                this.currentTime = `${yy}-${mm}-${dd} ${hours}:${minutes}:${seconds}`; // 更新显示的时间
            }, 1000); // 设置定时器为每秒执行一次
        },
        async getCompanyName() {
            await request('get', "/customers/devices/getTotalAndName/3185")
                .then((response) => {
                    this.companyName = response.data.data[0].dataName;
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
</script>
<style lang="scss" scoped>
.dv-border-box-1 {
    color: white;
    width: 450px;
    text-align: center;
    font-weight: 700;
    font-size: x-large;
    position: absolute;

    .title_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .company_name {
            width: 100%;
            margin: 13px;
        }

        .subtitle {
            width: 60%;
            font-size: large;
            font-weight: 600;
            margin: 16px;
        }

    }

}

.title_wrap {
    height: 60px;
    background-image: url("../../assets/img/top.png");
    background-size: cover;
    background-position: center center;
    position: relative;
    margin-bottom: 4px;

    .guang {
        position: absolute;
        bottom: -26px;
        background-image: url("../../assets/img/guang.png");
        background-position: 80px center;
        width: 100%;
        height: 56px;
    }

    .zuojuxing,
    .youjuxing {
        position: absolute;
        top: -2px;
        width: 140px;
        height: 6px;
        background-image: url("../../assets/img/headers/juxing1.png");
    }

    .zuojuxing {
        left: 11%;
    }

    .youjuxing {
        right: 11%;
        transform: rotate(180deg);
    }

    .timers {
        width: 450px;
        height: 60px;
        position: absolute;
        right: -4px;
        top: 0px;
        font-size: 30px;
        font-weight: 600;
        color: aliceblue;

        .title_container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 15px;

        }
    }
}

.title {
    position: relative;
    // width: 500px;
    text-align: center;
    background-size: cover;
    color: transparent;
    height: 60px;
    line-height: 46px;

    .title-text {
        font-size: 38px;
        font-weight: 900;
        letter-spacing: 6px;
        width: 100%;
        background: linear-gradient(92deg,
                #0072ff 0%,
                #00eaff 48.8525390625%,
                #01aaff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
</style>