<template>
    <dv-full-screen-container>
        <div class="container">
            <Header title="订单"></Header>
            <div class="content">
                <div class="left_container">
                    <div class="cell">
                        <Border title="订单总览">
                            <div class="cell_content">
                                <DigitalFlopOrderLite title="发货总金额" />
                                <DigitalFlopOrderLite title="签收总金额" />
                                <DigitalFlopOrderLite title="年度发货总金额" />
                                <DigitalFlopOrderLite title="年度签收总金额" />
                                <DigitalFlopOrderLite title="当月发货金额" />
                                <DigitalFlopOrderLite title="当月签收金额" />
                                <DigitalFlopOrderLite title="当日发货" />
                                <DigitalFlopOrderLite title="当日签收" />
                            </div>
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="当日销售排行">
                            <div class="cell_rank_hero">
                                    <RankHero title="0" />
                                    <RankHero title="1" />
                                    <RankHero title="2" />
                                    <RankHero title="3" />
                                    <RankHero title="4" />
                                    <RankHero title="5" />
                            </div>
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="当月小组排名">
                            <RankGroup />
                        </Border>
                    </div>
                </div>

                <div class="center_container">
                    <div class="cell_bottom">
                        <div class="cell" style="height: 100%;">
                            <OrderMap />
                        </div>
                        <div class="cell">
                            <DealLineCharts title="当月成交曲线"/>
                        </div>
                    </div>
                </div>

                <div class="right_container">
                    <div class="cell">
                        <Border title="数据总览">
                            <div class="cell_content">
                                <DigitalFlopOrder title="年度存量成交金额" />
                                <DigitalFlopOrder title="年度增量成交金额" />
                                <DigitalFlopOrder title="当月存量成交金额" />
                                <DigitalFlopOrder title="当月增量成交金额" />
                            </div>
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="当月渠道分布">
                            <RingChannel title="客户" />
                        </Border>
                    </div>
                    <div class="cell">
                        <Border title="当月发货排行">
                            <RankGoods />
                        </Border>
                    </div>
                </div>
            </div>
        </div>
    </dv-full-screen-container>
</template>
<script>
import Header from './index/Header.vue';
import Border from './index/Border.vue';
import RankGroup from './index/RankGroup.vue';
import RankGoods from './index/RankGoods.vue';
import OrderMap from './index/OrderMap.vue';
import RankHero from './index/RankHero.vue';
import RingChannel from './index/RingChannel.vue';
import DealLineCharts from './index/DealLineCharts.vue';
import DigitalFlopOrder from './index/DigitalFlopOrder.vue';
import DigitalFlopOrderLite from './index/DigitalFlopOrderLite.vue';
export default {
    components: {
    Header, Border, RankGroup, RankGoods, OrderMap, RankHero,
    RingChannel,
    DealLineCharts,
    DigitalFlopOrder,
    DigitalFlopOrderLite
}
}
</script>
<style lang="scss" scoped>
.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("@/assets/img/pageBg.png");
    background-size: cover;
    background-position: center center;

    .content {
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        justify-content: space-between;

        .left_container,
        .right_container {
            display: flex;
            flex-direction: column;
            width: 23%;
            height: 100%;

            .cell {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;

                .cell_content {
                    padding-right: 35px;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                }
            }
        }

        .center_container {
            display: flex;
            flex-direction: column;
            width: 53%;
            height: 100%;
            justify-content: space-between;

            .cell_top {
                height: 180px;
                width: 100%;
                display: flex;
                color: aliceblue;
                flex-direction: row;
                flex: 1;

                .cell_content {
                    width: 70%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    flex-wrap: no-wrap;
                    padding: 0 150px;
                }
            }

            .cell_bottom {
                height: calc(100% - 180px); // 减去cell_top的高度
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .cell {
                    flex: 1;
                    height: 45%;
                    width: 100%;
                }
            }
        }
    }

    .cell_rank_hero {
        display: flex;
        flex-direction: row; 
        flex-wrap: wrap;
        justify-content:flex-start;
        width: 100%;
    }
}</style>