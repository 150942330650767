<template>
    <div class="content">
        <dv-scroll-board :config="config" style="width:500px;height:250px" />
    </div>
</template>  
  
<script>
import request from '@/request/http.js'

export default {
    data() {
        return {
            config: {
                header: ['省份', '数量', '占比'],
                data: [],
                index: true,
                columnWidth: [50],
                align: ['center'],
                headerBGC: '#37a2da',
                oddRowBGC: '#001c34',
                evenRowBGC: '#00345',
                headerHeight: 40,
                indexHeader: '排名',
                rowNum: 5,
            },
            rawData: [] // 新增原始数据变量  
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() { // 使用async/await处理异步操作  
            await request('get', "/customers/getAllTopArea/3185")
                .then((response) => {
                    this.rawData = response.data.data[0];
                    for (var i = 0; i < this.rawData.length; i++) {
                        this.config.data.push([
                            this.rawData[i].name,
                            this.rawData[i].count,
                            this.rawData[i].rate
                        ]);
                    } this.config = { ...this.config };
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}  
</script>  
  
<style lang="scss" scoped>  .content {
      width: 100%;
      padding: 5%;
  }
</style>