<template>
    <div class="box">
        <dv-decoration-9 style="width:120px;height:120px;"><p>{{name}}<br/>{{amount}}</p></dv-decoration-9>
    </div>
</template>
<script>
import request from '@/api/erpHttp.js'
export default {
    data() {
        return {
            name:'',
            amount:'',
        }
    }, 
    props: {
        title: {
            type: String,
            required: true,
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            var url = '/orders/salesTop/3185';
            var that = this;
            await request('get', url)
                .then((response) => {
                    var data = response.data.data[0];
                    that.name = data[this.title].name;                    
                    that.amount = data[this.title].sum;
                    this.config = { ...this.config };//必不可少，不可省略
                })
                .catch((error) => {
                    //console.error(error);
                });
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 30%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
    margin-top: 10px;
    .dv-decoration-9 {
        width: 100%;
        font-size: 18px;
        word-wrap: break-word;
        color: white;
        font-weight: 600;
    }
}
</style>