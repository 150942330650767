<template>
    <div class="box">
        <div id="columnCharts" :style="{ width: '1000px', height: '550px' }"></div>
    </div>
</template>
<script>
import request from '@/api/erpHttp.js'
import * as echarts from 'echarts/lib/echarts'
import china from '@/request/china.js'

export default {
    data() {
        return {
            outname: ["南海诸岛"],
            count: [0],
            amount: [0],
            outdata: []
        }
    },

    created() {
    },
    methods: {
    },
    mounted() {
        request('get', '/orders/map/3185')
            .then((response) => {
                response.data.data[0].forEach(res => {
                    this.outname.push(res.district),
                        this.count.push(res.count),
                        this.amount.push(res.amount)

                    for (var i = 0; i < this.outname.length; i++) {
                        this.outdata.push({
                            name: this.outname[i],
                            value: this.count[i] + '单' + this.amount[i] + '元'
                        })
                    }
                    var geoCoordMap = {};
                    /*获取地图数据*/
                    var mapFeatures = echarts.getMap('china').geoJson.features;
                    mapFeatures.forEach(function (v) {
                        // 地区名称
                        var name = v.properties.name;
                        // 地区经纬度
                        geoCoordMap[name] = v.properties.cp;

                    });
                    var convertData = function (outdata) {
                        var res = [];
                        for (var i = 0; i < outdata.length; i++) {
                            var geoCoord = geoCoordMap[outdata[i].name];
                            if (geoCoord) {
                                res.push({
                                    name: outdata[i].name,
                                    value: geoCoord.concat(outdata[i].value),
                                });
                            }
                        }
                        return res;
                    };

                    var option = {
                        title: {
                            text: '订单年度成交',
                            top: '0%',
                            left: 'center',
                            textStyle: {
                                color: '#FFF',
                                align: 'center',
                                fontSize: 26,
                                fontWeight: '700'
                            }
                        },
                        tooltip: {
                            show: true,
                            formatter: function (params) {
                                if (!params.data) {
                                    return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + 0 + '&nbsp;&nbsp;';
                                } else {
                                    let value = Array.isArray(params.data.value)? params.data.value[2]:params.data.value;
                                    return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + value + '&nbsp;&nbsp;';
                                }
                            },

                        },

                        geo: {
                            map: 'china',
                            show: true,
                            roam: false,
                            label: {
                                emphasis: {
                                    show: false
                                }
                            },
                            layoutSize: "100%",
                            itemStyle: {
                                normal: {
                                    borderColor: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#00F6FF'
                                    }, {
                                        offset: 1,
                                        color: '#53D9FF'
                                    }], false),
                                    borderWidth: 3,
                                    shadowColor: 'rgba(10,76,139,1)',
                                    shadowOffsetY: 0,
                                    shadowBlur: 60
                                }
                            }
                        },
                        series: [{
                            type: 'map',
                            map: 'china',
                            aspectScale: 0.75,
                            //zoom:1.1,
                            label: {
                                normal: {
                                    show: false,
                                },
                                emphasis: {
                                    show: false,
                                }
                            },
                            itemStyle: {
                                normal: {
                                    areaColor: {
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0,
                                            color: '#073684' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: '#061E3D' // 100% 处的颜色
                                        }],
                                    },
                                    borderColor: '#215495',
                                    borderWidth: 1,
                                },
                                emphasis: {
                                    areaColor: {
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0,
                                            color: '#073684' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: '#061E3D' // 100% 处的颜色
                                        }],
                                    },
                                }
                            },
                            data: this.outdata,
                        }, {
                            //设置为分散点
                            type: 'scatter',
                            //series坐标系类型
                            coordinateSystem: 'geo',
                            //设置图形 'circle', 'rect', 'roundRect', 'triangle', 'diamond', 'pin', 'arrow'
                            symbol: 'pin',
                            // //标记的大小，可以设置成诸如 10 这样单一的数字，也可以用数组分开表示宽和高，例如 [20, 10] 表示标记宽为20，高为10
                            symbolSize: [45, 45],
                            //气泡字体设置
                            label: {
                                normal: {
                                    show: true,//是否显示
                                    textStyle: {
                                        color: '#fff',//字体颜色
                                        fontSize: 14,//字体大小
                                    },
                                    //返回气泡数据
                                    formatter(para) {
                                        var temp = para.data.value[2];
                                        return temp.split('单')[0]
                                    }
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#1E90FF', //标志颜色
                                }
                            },
                            //给区域赋值
                            data: convertData(this.outdata),
                            showEffectOn: 'render',//配置何时显示特效。可选：'render' 绘制完成后显示特效。'emphasis' 高亮（hover）的时候显示特效。
                            rippleEffect: {//涟漪特效相关配置。
                                brushType: 'stroke'//波纹的绘制方式，可选 'stroke' 和 'fill'
                            },
                            hoverAnimation: true,//是否开启鼠标 hover 的提示动画效果。
                            zlevel: 1//所属图形的 zlevel 值                
                        }]
                    };

                    // 基于准备好的dom，初始化echarts实例
                    var myChart = echarts.init(document.getElementById('columnCharts'))


                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option)
                });
            })
            .catch((error) => {
                console.error(error);
            });

    }

}
</script>
<style scoped>
.box {
    margin-top: 50px;
}
</style>